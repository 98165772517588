import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Section,
  BreadCrumb,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import LazyLaod from "react-lazyload"
import CallToAction from "../components/CallToAction"
import DesignPlan from "../components/DesignPlan"
import AccreditedBusiness from "../components/AccreditedBusiness"
import {
  HeroSection,
  HeroCover,
  LeftHero,
  RightHero,
  HeroPageTitle,
  HeroDesc,
  HeroFormWrap,
  HeroFormTitle,
  HeroFormBody,
} from "../components/Hero"
import QuickContactForm from "../components/QuickContactForm"
import CardSection from "../sections/Home/CardSection"

class ProductCategoryTemplate extends React.Component {
  render() {
    const pageData = this.props.data.contentfulCityLanding
    const AllProducts = this.props.data.allContentfulProduct.edges.filter(
      edge =>
        edge.node.productCategory &&
        edge.node.productCategory.name &&
        edge.node.productRoofType &&
        edge.node.productRoofType.name
    )

    return (
      <Layout location={this.props.location}>
        <SEO
          title={`Metal Buildings ${pageData.cityName} - Prefab Steel Buildings And Kits For Sale In ${pageData.cityName}, ${pageData.state.abbreviation}`}
          description={`Metal Buildings ${pageData.cityName} - Discover extensive range of steel buildings, metal garages, RV carports, metal barns and kits in ${pageData.cityName}. Order your building now.`}
        />
        <HeroSection>
          <div className="container">
            <BreadCrumb className="static">
              <Link to="/">Home</Link> /{" "}
              <Link to={pageData.state.url}>{pageData.state.stateName}</Link> /{" "}
              <span> {pageData.heroTitle}</span>
            </BreadCrumb>
            <HeroCover>
              <LeftHero>
                <HeroPageTitle>{pageData.heroTitle}</HeroPageTitle>
                <HeroDesc>
                  <MarkdownContent
                    dangerouslySetInnerHTML={{
                      __html: pageData.heroDescription.childMarkdownRemark.html,
                    }}
                  />
                </HeroDesc>
              </LeftHero>
              <RightHero>
                <HeroFormWrap>
                  <HeroFormTitle>Get In Touch</HeroFormTitle>
                  <HeroFormBody>
                    <QuickContactForm
                      location={this.props.location}
                      formName={`${pageData.cityName} Product Contact Form`}
                    />
                  </HeroFormBody>
                </HeroFormWrap>
              </RightHero>
            </HeroCover>
          </div>
        </HeroSection>

        <LazyLaod>
          <CardSection 
						data={{
							title: "Check Out Our Top-Selling Buildings",
							description: {}
						}} 
						productData={AllProducts} 
					/>
        </LazyLaod>
        <CallToAction />
        <Section pt="90px" pb="90px">
          <div className="container">
            <LeftPannel>
              <MarkdownContent>
                <h2>
                  The Largest Range of Steel Buildings - {pageData.cityName},{" "}
                  {pageData.state.stateName}
                </h2>
                <p>
                  Here at Probuilt Steel Buildings, we are proud to offer our
                  exceptional metal buildings to {pageData.cityName},{" "}
                  {pageData.state.abbreviation} -based clients. Each of our
                  buildings is designed to withstand hurricane-force winds so
                  that you can feel confident that your building will last the
                  test of time.
                </p>
                <h2>
                  Our Range of Prefabricated Metal Buildings in{" "}
                  {pageData.cityName}, {pageData.state.abbreviation}
                </h2>
                <p>
                  Steel buildings come in a huge range of shapes and sizes to
                  fit your requirements. The following list covers the most
                  popular metal buildings we sell:
                </p>
                <h3>Steel Carports</h3>
                <p>
                  Not only do these provide shelter for your vehicle, but they
                  will also protect them from damage and give you an open space
                  to work on vehicle maintenance without being exposed to
                  weather conditions.
                </p>
                <h3>Steel Garages</h3>
                <p>
                  Install a new garage and park your car with confidence. With
                  1, 2, 3, 4, or more car space options, we can deliver a
                  building that gives you the space you need.
                </p>
                <h3>Metal RV Covers</h3>
                <p>
                  Want somewhere safe to store and service your RV? A steel RV
                  cover is a great choice, and seriously quick to install.
                </p>
                <h3>Steel Barns</h3>
                <p>
                  Get the space you need for your farm, workshop, or storage
                  with a steel barn. They come in a range of sizes and are
                  customizable.
                </p>
                <h3>Utility Carports</h3>
                <p>
                  Enjoy a carport for your vehicle with the added bonus of
                  enclosed storage space.
                </p>
                <h3>Storage Buildings</h3>
                <p>
                  No matter how much you have to store, we can provide you with
                  a building that suits your needs.
                </p>
                <h3>Commercial Metal Buildings</h3>
                <p>
                  Installing a steel building for commercial purposes will allow
                  you to create a long-lasting commercial space that will help
                  you grow your business.
                </p>
                <h3>Metal Building Homes</h3>
                <p>
                  Want to build your own home? Opt for a metal building for a
                  cheaper and more durable construction.
                </p>
                <h3>Custom Metal Buildings</h3>
                <p>
                  Create the exact space you need with our customization
                  options, and let our team help you to create your dream
                  building.
                </p>
                <h2>
                  How to Customize Steel Buildings in {pageData.cityName},{" "}
                  {pageData.state.stateName}
                </h2>
                <p>
                  There is nothing better than being able to customize your new
                  metal building so that it meets your needs perfectly. Here are
                  some of the most common ways you can customize yours:
                </p>

                <h3>Building Dimensions</h3>
                <p>
                  Get the size you want when you come to us! We can work with
                  you on width, length, and height to get the building that
                  suits your needs.
                </p>

                <h3>Roof Styles</h3>
                <p>
                  With three main roof styles on offer, you can get the one that
                  works best for you!
                </p>
                <p>
                  <strong>Regular Roof </strong>&ndash; this curved-style roof
                  is one of the most inexpensive choices and is easy to
                  maintain. Ideal for areas that do not experience extreme
                  weather.
                </p>
                <p>
                  <strong>A-Frame Horizontal Roof </strong>&ndash; this is the
                  same style that many houses have and works well for buildings
                  that you want to blend in with your home. The panels run from
                  side to side and work well in warm climates.
                </p>
                <p>
                  <strong>Vertical Roof </strong>&ndash; great for areas that
                  experience extreme weather, this roof has panels that run from
                  the eaves to the pitch so that snow, hail, and rain runoff
                  with ease.
                </p>
                <h3>Steel Gauges</h3>
                <p>
                  You pick the gauge you want from our range to ensure that your
                  steel building is as strong as possible.
                </p>
                <h3>Certification</h3>
                <p>
                  We can even provide metal buildings that are fully certified
                  to support your planning applications and zoning requirements.
                </p>
                <h3>Panels, Doors, and Windows</h3>
                <p>
                  You pick where you want them and how many you want, and we
                  will ensure that the building is engineered ready for
                  installation.
                </p>
                <h3>Gables, Trim, Wainscoting, and Anchors</h3>
                <p>
                  Each element is fully customizable so that your end result is
                  a steel building that fits your needs and your design ideas.
                </p>
                <h3>Colors</h3>
                <p>
                  With thirteen colors on offer, you can create a building that
                  suits your style and the area of {pageData.state.stateName}{" "}
                  that you live in.
                </p>
                <h3>And more!</h3>
                <p>
                  Just let us know what you are looking for, and we will do our
                  best to supply it.
                </p>

                <h2>The Best Metal Building {pageData.cityName} Prices</h2>
                <p>
                  If you are planning a new steel project, then you are sure to
                  want to find out the metal building {pageData.cityName}{" "}
                  prices. Unfortunately, there is no one price fits all
                  available because each building is original and made to fit
                  your needs.
                </p>

                <p>
                  Some of the key factors that affect the price of a metal
                  building include:
                </p>
                <ul>
                  <li>Size</li>
                  <li>Customization options</li>
                  <li>Delivery location</li>
                  <li>Optional extras</li>
                  <li>And more</li>
                </ul>

                <p>
                  However, at Probuilt Steel Buildings, we are committed to
                  providing you with a transparent, no-obligation quote before
                  you part with any money. Plus, if you need to spread the cost
                  of the building, we can offer that too with either our
                  rent-to-own program or our traditional financing option, just
                  ask for more information.
                </p>

                <h2>
                  Why Not Invest in a Kit from the Best Metal Building
                  Manufacturers in {pageData.cityName},{" "}
                  {pageData.state.stateName}
                </h2>
                <p>
                  If you are a fan of DIY and want to reduce the cost of your
                  building, then why not take a look at our metal building kits
                  instead? The kit will be delivered pre-engineered and ready to
                  install so that all you need to do is take delivery and then
                  get to work. Many of our customers use our kits as an
                  opportunity to spend time with friends as you install your new
                  steel building. Plus, when you choose a kit from Probuilt
                  Steel Buildings, you can be confident that you will be getting
                  access to the best quality and best-priced option on the
                  market.
                </p>

                <h2>
                  The Many Benefits of Owning Steel Buildings in{" "}
                  {pageData.cityName}, {pageData.state.abbreviation}
                </h2>
                <p>
                  When you decided to invest in one of our metal buildings in{" "}
                  {pageData.cityName}, {pageData.state.stateName}, you will be
                  amazed at the number of benefits that are on offer! Take a
                  look at the most highly-rated benefits that our customers
                  love:
                </p>

                <ul>
                  <li>
                    Steel buildings are pest-resistant so that no rodents or
                    other animals will be able to make a home in your new
                    construction.
                  </li>
                  <li>
                    Metal buildings offer fire resistance and support in the
                    event of extreme weather conditions.
                  </li>
                  <li>
                    Your new building will be simple to install as long as you
                    take the time to install a level foundation ready for
                    delivery day.
                  </li>
                  <li>
                    Maintenance is a cinch with metal buildings so that you can
                    enjoy long-lasting protection without much effort.
                  </li>
                </ul>

                <h2>
                  Let Probuilt Steel Buildings Help You Get Your{" "}
                  {pageData.cityName} Building Sorted
                </h2>
                <p>
                  If you are ready to organize your new metal building, then get
                  in touch with us here at Probuilt Steel Buildings &ndash;
                  we&rsquo;d love to help! We offer free delivery and
                  installation on our buildings and ensure that all our options
                  are priced fairly and transparently. Call our team today at{" "}
                  <strong>
                    <a href="tel:8777541818">(877) 754-1818</a>{" "}
                  </strong>
                  to get a quote.
                </p>
              </MarkdownContent>
            </LeftPannel>
            <RightPannel>
              <DesignPlan />
              <AccreditedBusiness />
            </RightPannel>
          </div>
        </Section>
      </Layout>
    )
  }
}

export default ProductCategoryTemplate

export const pageQuery = graphql`
  query cityLandigQuery($id: String!) {
    contentfulCityLanding(id: { eq: $id }) {
      cityName
      heroTitle
      state {
        abbreviation
        stateName
        url
      }
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        title
        fluid(quality: 100) {
          aspectRatio
          base64
          sizes
          src
          srcWebp
          srcSetWebp
          srcSet
        }
      }
      productListTitle
    }
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          description {
            description
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
  }
`
